const Starter_Menu = (props) => {
  return (
    <ul>
      <p>This is Starter menu management section - menu management</p>
      <li>• update online menu at admin page</li>
      <li>• updated menu is saved on the cloud database.</li>
      <li>• full access adding, deleting, updatig price, name, descriptions - category may be instructed</li>
    </ul>
  );
}
export default Starter_Menu;