const Starter_Statement = (props) => {
  return (
    <>
      {/* Image holder */}
      {/* Title */}
      {/* Text */}
      <p>This is statement section</p>
    </>

  );
}
export default Starter_Statement;
