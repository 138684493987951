import { Outlet } from 'react-router-dom';

const Contact = (props) => {


  return (
    <>
      <Outlet />
    </>
  );
}
export default Contact;