const Starter_Email = (props) => {
  return (
    <ul>
      <p>This is FB starter email section - online marketing</p>
      <li>• up to 100 emails / month</li>
      <li>• easy to send emails to your customers</li>
      <li>• pre-formatted email templates - upgragable</li>
      <li>• draw and keep valuable customers</li>
    </ul>
  );
}
export default Starter_Email;