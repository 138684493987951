const Starter_Hosting = (props) => {
  return (
    <ul>
      <p>Web Hosting, Domains section</p>
      <li>• Worry free for domain maintenanace</li>
      <li>• Web server hosting</li>
      <li>• Cloud database usage</li>
      <li>• email account with same domain ending provided. for admin and employee</li>
    </ul>
  );
}
export default Starter_Hosting;